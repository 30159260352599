<template>
  <div>
    <div class="qkxx_title">待结算稿件</div>
    <el-form class="searchForm"
      ><div class="search">
        <el-input
          class="mySearch"
          placeholder="请输入文章标题/订单id查询"
          v-model="keywords"
        ></el-input>
      </div>
      <div class="searchContainerBtn">
        <el-button type="danger" @click="search()">查询</el-button>
      </div>
    </el-form>
    <div>
      <router-link to="/Periodical/unbalanced/apply">
        <el-button> 申请结算 </el-button>
      </router-link>
      <router-link to="/Periodical/voucher">
        <el-button type="danger"
          ><div style="color: #fff">查看凭证</div></el-button
        >
      </router-link>
    </div>
    <div v-if="orderlistlength == 0" class="nodata">暂无数据</div>
    <div v-else>
      <div class="qkcont">
        <order :orderlist="orderlist"></order>
      </div>
      <div class="ygdPage">
        <el-pagination
          class="fy"
          small
          @size-change="handleSizeChange"
          :page-size="pagesize"
          :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="current_change"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import order from "../components/balanceorder.vue";
import { unbanlancedata } from "../../../API/QKD/list.js";
export default {
  components: {
    order,
  },
  data() {
    return {
      son: 1,
      path: "/Periodical/unbalanced",
      now: 8,
      keywords: "",
      childpath: "/Periodical/balanced/detail",
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      orderlist: [
        //订单列表数据
      ],
      orderlistlength: 2, //订单列表长度
      trace: [
        //跟踪数据
      ],
      article: {},
    };
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.orderdata();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.orderdata();
    },
    orderdata() {
      let id = localStorage.getItem("QKId");
      unbanlancedata(id, this.currentPage, this.pagesize, this.keywords).then(
        (res) => {
          console.log(res);
          this.orderlist = res.data.data;
          this.orderlistlength = this.orderlist.length;
          this.total = res.data.total;
        }
      );
    },
    search(){
      this.currentPage = 1;
      this.orderdata();
    },
  },
  mounted() {
    this.orderdata();
  },
  
};
</script>

<style>
.qkcont {
  padding: 10px;
}
.nodata {
  text-align: center;
  line-height: 80px;
  color: #e6e6e6;
}
.btn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.searchForm{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 0;
}
.search{
  margin-right: 16px;
}
.mySearch{
  width: 280px;
}
</style>